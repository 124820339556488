import React, { useState, ReactNode, useEffect } from 'react';
import DrawerBody from '@/components/drawers/DrawerBody';
import DrawerHeader from '@/components/drawers/DrawerHeader';
import { TextInput } from '@/components/form/TextInput';
import { FormTextInput } from '@/components/form/FormTextInput';
import { ButtonV2 } from '@/components/button/ButtonV2';
import { Icon } from '@/components/icon/Icon';
import { useForm, usePage } from '@inertiajs/react';
import ReactMarkdown from 'react-markdown';
import { Accordion } from '@/components/accordion/Accordion';
import { ConversationsDropdown } from '@/components/dropdown/ConversationsDropdown';
import { Popover } from '@headlessui/react';
import { Dropdown } from '@/components/dropdown/Dropdown';
import { dropdownOffset, Popper } from '@/components/popper/Popper';
import { Link } from '@/components/link/Link';

interface Props {
  onClose: () => void;
}

// Add new interface for cadence form state
interface CadenceFormState {
  productType: string | null;
  releaseDate: string | null;
  singlesCount: number;
}

type AIModel = 'atlas' | 'gpt-3.5-turbo' | 'gpt-4' | 'claude-3-haiku';

interface ModelOption {
  id: AIModel;
  name: string;
  description: string;
}

const modelOptions: ModelOption[] = [
  {
    id: 'atlas',
    name: 'claude 3 opus',
    description: 'Powered by Claude 3 Opus - Most capable model for complex tasks'
  },
  {
    id: 'claude-3-haiku',
    name: 'claude 3 haiku',
    description: 'Fast and efficient model via Bedrock - Great for quick responses'
  },
  {
    id: 'gpt-3.5-turbo',
    name: 'gpt-3.5-turbo',
    description: 'Fast and efficient model for simple tasks'
  },
  {
    id: 'gpt-4',
    name: 'gpt-4 turbo',
    description: 'Powerful model for general tasks'
  }
];

interface Message {
  role: 'user' | 'assistant';
  content: string;
  timestamp: string;
  response?: AIResponse;
}

interface AIResponse {
  answer?: string;
  response?: string;
  timestamp?: string;
  conversation_id?: string;
  metadata?: {
    raw_schedule?: any;
    metadata?: {
      raw_schedule?: any;
    };
    artist?: {
      name: string;
      hash_id: string;
    };
  };
  debug?: {
    prompt?: string;
    context?: any;
    pageType?: string;
    reflection?: string;
    search_quality_score?: number;
    model?: {
      name: string;
      version?: string;
      provider?: string;
    };
    usage?: {
      prompt_tokens: number;
      completion_tokens: number;
      total_tokens: number;
      estimated_cost_usd?: number;
    };
    tools_used?: Array<{
      name: string;
      purpose: string;
      timestamp: string;
    }>;
  };
  state?: {
    current_step?: string;
    is_active?: boolean;
    schedule_generated?: boolean;
    product_type?: string | null;
    release_date?: string | null;
    singles_count?: number | null;
    artist_id?: number | null;
  };
}

interface AIMessageResponse {
  role: 'user' | 'assistant';
  content: string;
  created_at: string;
  metadata?: {
    context?: any;
    response?: string;
    debug?: any;
    tools_used?: Array<{
      name: string;
      purpose: string;
      timestamp: string;
    }>;
  };
}

interface ModelUsage {
  prompt_tokens: number;
  completion_tokens: number;
  total_tokens: number;
  estimated_cost_usd: number;
}

interface ModelUsageMetrics {
  atlas: ModelUsage;
  'claude-3-haiku': ModelUsage;
  'gpt-3.5-turbo': ModelUsage;
  'gpt-4': ModelUsage;
}

// Constants
const TOKEN_LIMIT = 200000; // Claude 3 Opus context window limit

interface Community {
  id: number;
  name: string;
  display_name: string;
}

interface Artist {
  id: number;
  name: string;
  community?: Community;
}

interface Product {
  id: number;
  title: string;
}

interface Video {
  id: number;
  title: string;
}

interface SoundRecording {
  id: number;
  title: string;
}

interface PageProps {
  component?: string;
  artist?: Artist;
  collaborator?: any;
  product?: Product;
  project?: any;
  video?: Video;
  soundRecording?: SoundRecording;
}

interface PageData {
  props: PageProps;
  [key: string]: any; // Add index signature to satisfy constraint
}

// Add helper to check if we're at product type selection
const isAtProductTypeSelection = (messages: Message[]): boolean => {
  const lastAssistantMessage = [...messages].reverse().find((m) => m.role === 'assistant');
  return Boolean(
    lastAssistantMessage?.content?.includes(
      'To begin planning your release schedule, please select whether this will be an Album or EP campaign.'
    )
  );
};

// Add helper to check if we're at release date selection
const isAtReleaseDateSelection = (messages: Message[]): boolean => {
  const lastAssistantMessage = [...messages].reverse().find((m) => m.role === 'assistant');
  return Boolean(lastAssistantMessage?.content?.includes('When would you like to release this'));
};

// Add helper to check if we're at singles count selection
const isAtSinglesCountSelection = (messages: Message[]): boolean => {
  const lastAssistantMessage = [...messages].reverse().find((m) => m.role === 'assistant');
  const lastUserMessage = [...messages].reverse().find((m) => m.role === 'user');
  return Boolean(
    lastAssistantMessage?.content?.includes('How many singles would you like to release') &&
      !lastUserMessage?.content?.includes('Plan a cadence for a')
  );
};

// Add helper to check if we're at schedule generated state
const isAtScheduleGeneratedState = (messages: Message[]): boolean => {
  const lastAssistantMessage = [...messages].reverse().find((m) => m.role === 'assistant');
  return Boolean(
    lastAssistantMessage?.response?.state?.current_step === 'schedule_generated' &&
      lastAssistantMessage?.response?.state?.is_active === true
  );
};

// Add helper to check if we're in any cadence planning step
const isInCadencePlanningStep = (messages: Message[]): boolean => {
  return (
    isAtProductTypeSelection(messages) ||
    isAtReleaseDateSelection(messages) ||
    isAtSinglesCountSelection(messages) ||
    isAtScheduleGeneratedState(messages)
  );
};

export function AIAssistantDrawer({ onClose }: Props) {
  const { props } = usePage<PageData>();
  const pageProps = props as PageProps;
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ReactNode | null>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [selectedModel, setSelectedModel] = useState<AIModel>('gpt-3.5-turbo');
  const [conversations, setConversations] = useState<
    Array<{
      id: string;
      title: string;
      created_at: string;
      last_message?: string;
    }>
  >([]);
  const [isLoadingConversations, setIsLoadingConversations] = useState(false);
  const [conversationsError, setConversationsError] = useState<string | null>(null);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  // Add state for cadence form
  const [cadenceForm, setCadenceForm] = useState<CadenceFormState>({
    productType: null,
    releaseDate: null,
    singlesCount: 0
  });

  // New state for tracking model-specific metrics
  const [modelMetrics, setModelMetrics] = useState<ModelUsageMetrics>({
    atlas: {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
      estimated_cost_usd: 0
    },
    'claude-3-haiku': {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
      estimated_cost_usd: 0
    },
    'gpt-3.5-turbo': {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
      estimated_cost_usd: 0
    },
    'gpt-4': {
      prompt_tokens: 0,
      completion_tokens: 0,
      total_tokens: 0,
      estimated_cost_usd: 0
    }
  });

  // Add a computed value to determine if we should show the standalone loading indicator
  const shouldShowStandaloneLoadingIndicator =
    isLoading &&
    !messages.some(
      (msg, idx) =>
        idx === messages.length - 1 &&
        msg.role === 'user' &&
        (!isInCadencePlanningStep(messages) ||
          (msg.content.startsWith('Plan a cadence for a') && !msg.content.match(/^\d+$/)))
    );

  // Add state for welcome message
  const [welcomeMessage] = useState(() => {
    const welcomeMessages = [
      "Ready to explore? I'll be your guide! 🗺️",
      'Hey there! Let me help you chart the course ahead ⭐',
      'Your friendly AI navigator here! Where to? 🧭',
      'Looking for direction? Atlas at your service! 🌟',
      "Let's map out something amazing together! 🗺️",
      "Need a guide through the data? That's what I'm here for! ✨",
      'Your digital compass, ready to point the way! 🧭',
      "Let's discover something extraordinary together! 🔭",
      'Atlas here, ready to help you navigate the journey! 🧭',
      'Charting new territories together! Where shall we begin? 🗺️',
      'Your data expedition guide, at your service! ⭐',
      'Ready to map out your next big adventure? 🌍',
      "Let's explore uncharted territories together! 🧭",
      'Your compass through complexity, how can I guide you? 🌟',
      'Atlas ready to illuminate the path forward! ✨',
      'Shall we embark on a data discovery journey? 🔭',
      'Your wayfinder through the digital landscape! 🗺️',
      'Ready to be your guide through this expedition! ⭐',
      "Let's navigate these waters together! 🧭",
      'Your cartographer of possibilities, at your service! 🌍'
    ];
    return welcomeMessages[Math.floor(Math.random() * welcomeMessages.length)];
  });

  // Calculate model-specific metrics whenever messages change
  useEffect(() => {
    const newMetrics: ModelUsageMetrics = {
      atlas: {
        prompt_tokens: 0,
        completion_tokens: 0,
        total_tokens: 0,
        estimated_cost_usd: 0
      },
      'claude-3-haiku': {
        prompt_tokens: 0,
        completion_tokens: 0,
        total_tokens: 0,
        estimated_cost_usd: 0
      },
      'gpt-3.5-turbo': {
        prompt_tokens: 0,
        completion_tokens: 0,
        total_tokens: 0,
        estimated_cost_usd: 0
      },
      'gpt-4': {
        prompt_tokens: 0,
        completion_tokens: 0,
        total_tokens: 0,
        estimated_cost_usd: 0
      }
    };

    messages.forEach((message) => {
      if (message.response?.debug?.usage && message.response?.debug?.model?.name) {
        const usage = message.response.debug.usage;
        const model = message.response.debug.model.name.toLowerCase().includes('claude')
          ? 'atlas'
          : message.response.debug.model.name.toLowerCase().includes('gpt-4')
            ? 'gpt-4'
            : 'gpt-3.5-turbo';

        newMetrics[model].prompt_tokens += usage.prompt_tokens || 0;
        newMetrics[model].completion_tokens += usage.completion_tokens || 0;
        newMetrics[model].total_tokens += usage.total_tokens || 0;
        newMetrics[model].estimated_cost_usd += Number(usage.estimated_cost_usd || 0);
      }
    });

    setModelMetrics(newMetrics);
  }, [messages]);

  // Function to get total tokens across all models
  const getTotalTokens = () => {
    return (
      modelMetrics.atlas.total_tokens +
      modelMetrics['claude-3-haiku'].total_tokens +
      modelMetrics['gpt-3.5-turbo'].total_tokens +
      modelMetrics['gpt-4'].total_tokens
    );
  };

  // Function to get total cost across all models
  const getTotalCost = () => {
    return (
      modelMetrics.atlas.estimated_cost_usd +
      modelMetrics['claude-3-haiku'].estimated_cost_usd +
      modelMetrics['gpt-3.5-turbo'].estimated_cost_usd +
      modelMetrics['gpt-4'].estimated_cost_usd
    );
  };

  // Update the token usage percentage calculation
  const getTokenUsagePercentage = () => (getTotalTokens() / TOKEN_LIMIT) * 100;

  // Function to determine token usage status
  const getTokenUsageStatus = () => {
    const percentage = getTokenUsagePercentage();
    if (percentage >= 90) return 'critical';
    if (percentage >= 80) return 'warning';
    return 'normal';
  };

  const form = useForm({
    question: '',
    model: selectedModel,
    releaseDate: '',
    context: Object.fromEntries(
      Object.entries({
        component: pageProps.component,
        artist: pageProps.artist,
        collaborator: pageProps.collaborator,
        product: pageProps.product,
        project: pageProps.project
      }).filter(([, value]) => value !== null && value !== undefined)
    )
  });

  // Update conversation ID when we get it from the response
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.response?.conversation_id && !conversationId) {
      setConversationId(lastMessage.response.conversation_id);
    }
  }, [messages]);

  // Add effect to update form when model changes
  useEffect(() => {
    form.setData('model', selectedModel);
  }, [selectedModel]);

  const fetchConversations = async () => {
    setIsLoadingConversations(true);
    setConversationsError(null);
    try {
      const response = await fetch('/ai/conversations', {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        credentials: 'same-origin'
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch conversations: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      setConversations(data.conversations || []);
    } catch (error) {
      console.error('Error fetching conversations:', error);
      setConversationsError('Failed to load conversations. Please try again.');
    } finally {
      setIsLoadingConversations(false);
    }
  };

  // Fetch conversations when drawer opens
  useEffect(() => {
    fetchConversations();
  }, []);

  const handleConversationSelect = async (conversationId: string) => {
    try {
      setError(null);
      const response = await fetch(`/ai/conversations/${conversationId}`, {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        credentials: 'same-origin'
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch conversation: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();

      if (!data.conversation || !Array.isArray(data.conversation.messages)) {
        throw new Error('Invalid response format');
      }

      const formattedMessages = data.conversation.messages.map((message: AIMessageResponse) => ({
        role: message.role,
        content: message.content,
        timestamp: message.created_at,
        ...(message.metadata && {
          response: {
            answer: message.content,
            timestamp: message.created_at,
            debug: message.metadata.debug,
            tools_used: message.metadata.tools_used
          }
        })
      }));

      setMessages(formattedMessages);
      setConversationId(conversationId);
      setError(null);
    } catch (error) {
      console.error('Error fetching conversation:', error);
      setError(<div>Failed to load conversation. Please try again.</div>);
    }
  };

  const handleConversationEdit = async (conversationId: string, newTitle: string) => {
    try {
      const response = await fetch(`/ai/conversations/${conversationId}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        body: JSON.stringify({ title: newTitle })
      });

      if (!response.ok) {
        throw new Error('Failed to update conversation title');
      }

      // Update the conversation title in the local state
      setConversations((prev) =>
        prev.map((conv) => (conv.id === conversationId ? { ...conv, title: newTitle } : conv))
      );
    } catch (error) {
      console.error('Error updating conversation:', error);
      // You might want to show an error toast here
    }
  };

  const handleConversationDelete = async (targetConversationId: string) => {
    try {
      const response = await fetch(`/ai/conversations/${targetConversationId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete conversation');
      }

      // Remove the conversation from the local state
      setConversations((prev) => prev.filter((conv) => conv.id !== targetConversationId));

      // If the deleted conversation was selected, clear the messages
      if (targetConversationId === conversationId) {
        setMessages([]);
        setConversationId(null);
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
      // You might want to show an error toast here
    }
  };

  // Update the conversation list when a new message is added
  const updateConversationsList = (newMessage: Message, conversationId: string, title: string) => {
    setConversations((prev) => {
      const existing = prev.find((c) => c.id === conversationId);
      if (existing) {
        return prev.map((c) =>
          c.id === conversationId ? { ...c, last_message: newMessage.content, title } : c
        );
      }
      return [
        {
          id: conversationId,
          title,
          created_at: new Date().toISOString(),
          last_message: newMessage.content
        },
        ...prev
      ];
    });
  };

  const handleStop = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (abortController) {
      console.log('Aborting request...');
      abortController.abort();
      setAbortController(null);
      setIsLoading(false);
      // Remove the last message (the user's question that was stopped)
      setMessages((prev) => prev.slice(0, -1));
      console.log('Request aborted and message removed');
    }
  };

  const submitQuestion = (question: string) => {
    form.setData('question', question);
    // Use setTimeout to ensure the form data is set before submitting
    setTimeout(() => {
      formRef.current?.requestSubmit();
    }, 0);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!form.data.question.trim()) return;

    // If there's an existing request, stop it and remove its message
    if (abortController) {
      abortController.abort();
      setAbortController(null);
      setMessages((prev) => prev.slice(0, -1));
    }

    const newUserMessage = {
      role: 'user' as const,
      content: form.data.question,
      timestamp: new Date().toISOString(),
      response: form.data.question.toLowerCase().includes('plan a cadence')
        ? {
            state: {
              current_step: 'product_type',
              is_active: true,
              schedule_generated: false
            }
          }
        : undefined
    };
    setMessages((prev) => [...prev, newUserMessage]);

    setIsLoading(true);
    setError(null);

    // Create new abort controller
    const controller = new AbortController();
    setAbortController(controller);

    try {
      // Create request data with latest conversation ID from state
      const requestData = {
        question: form.data.question,
        model: form.data.model,
        context: form.data.context ? JSON.stringify(form.data.context) : '{}',
        conversation_id: conversationId
      };

      const response = await fetch('/ai/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        body: JSON.stringify(requestData),
        signal: controller.signal,
        credentials: 'same-origin'
      });

      // Check for error responses first
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      // Only proceed with streaming for successful responses
      const contentType = response.headers.get('content-type');
      const transferEncoding = response.headers.get('transfer-encoding');
      const isStreamable =
        contentType?.includes('text/event-stream') ||
        contentType?.includes('application/json') ||
        transferEncoding === 'chunked';

      if (isStreamable && response.body) {
        setIsLoading(false);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let accumulatedData = '';

        try {
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;

            accumulatedData += decoder.decode(value, { stream: true });
            await new Promise((resolve) => setTimeout(resolve, 100));

            try {
              const data = JSON.parse(accumulatedData);
              if (data.answer) {
                const newAssistantMessage = {
                  role: 'assistant' as const,
                  content: data.answer,
                  timestamp: data.timestamp,
                  response: {
                    ...data,
                    state: data.state || {
                      current_step: data.state?.current_step || 'product_type',
                      is_active: true,
                      ...data.state // Preserve any existing state properties
                    }
                  }
                };
                setMessages((prev) => [...prev, newAssistantMessage]);

                // Check if the AI response indicates switching to cadence planning mode
                if (data.answer.includes('CADENCE_PLANNING_MODE')) {
                  // Add the assistant's initial cadence planning message
                  const timestamp = new Date().toISOString();
                  const content =
                    'To begin planning your release schedule, please select whether this will be an Album or EP campaign.';
                  const planningMessage: Message = {
                    role: 'assistant',
                    content,
                    timestamp,
                    response: {
                      answer: content,
                      timestamp,
                      state: {
                        current_step: 'product_type',
                        is_active: true,
                        schedule_generated: false // Explicitly set this to false at the start
                      }
                    }
                  };
                  setMessages((prev) => [...prev, planningMessage]);
                }
                break;
              }
            } catch (e) {
              continue;
            }
          }
        } finally {
          reader.releaseLock();
          await new Promise((resolve) => setTimeout(resolve, 200));
          setIsLoading(false);
          form.setData('question', '');
        }
        return;
      }

      // Fall back to regular JSON handling if not streamed
      if (!contentType?.includes('application/json')) {
        // Clone response to read it twice (once for text, once for potential json)
        const responseClone = response.clone();
        const responseText = await response.text();
        console.error('Non-JSON Response received:', responseText);

        // Check if this looks like a timeout or 502 error
        if (
          responseText.includes('504') ||
          responseText.includes('502') ||
          responseText.toLowerCase().includes('gateway') ||
          response.status === 504 ||
          response.status === 502
        ) {
          throw new Error(
            'The request took too long to process. For complex queries like listing many items, try breaking it into smaller chunks or being more specific.'
          );
        }

        // Try to parse error response as JSON anyway
        try {
          const errorJson = await responseClone.json();
          console.error('Attempted JSON parse of error:', errorJson);
          throw new Error(
            errorJson.error || 'Server returned non-JSON response. Please try again.'
          );
        } catch (jsonError) {
          throw new Error(
            `The request was too complex to process. Try breaking it into smaller chunks or being more specific. For example, instead of asking for all items, try asking for them in groups of 10-15.`
          );
        }
      }

      const data = await response.json();

      if (!response.ok) {
        console.error('Error Response:', data);
        throw new Error(data.error || `Server error: ${response.status}`);
      }

      console.log('AI Response:', data);

      // Update conversation ID first if we got one
      if (data.conversation_id && data.conversation_id !== conversationId) {
        setConversationId(data.conversation_id);

        // Create a simple title from the first message
        const title =
          form.data.question.length > 30
            ? `${form.data.question.substring(0, 30)}...`
            : form.data.question;

        updateConversationsList(newUserMessage, data.conversation_id, title);
      }

      const newAssistantMessage = {
        role: 'assistant' as const,
        content: data.answer,
        timestamp: data.timestamp,
        response: {
          ...data,
          state: data.state || {
            current_step: data.state?.current_step || 'product_type',
            is_active: true,
            ...data.state // Preserve any existing state properties
          }
        }
      };
      setMessages((prev) => [...prev, newAssistantMessage]);

      // Check if the AI response indicates switching to cadence planning mode
      if (data.answer.includes('CADENCE_PLANNING_MODE')) {
        // Add the assistant's initial cadence planning message
        const timestamp = new Date().toISOString();
        const content =
          'To begin planning your release schedule, please select whether this will be an Album or EP campaign.';
        const planningMessage: Message = {
          role: 'assistant',
          content,
          timestamp,
          response: {
            answer: content,
            timestamp,
            state: {
              current_step: 'product_type',
              is_active: true,
              schedule_generated: false // Explicitly set this to false at the start
            }
          }
        };
        setMessages((prev) => [...prev, planningMessage]);
      }

      form.setData('question', '');
    } catch (error: unknown) {
      // Don't show error if request was aborted
      if (error instanceof Error && error.name === 'AbortError') {
        console.log('Request was aborted');
        return;
      }

      console.error('Error querying AI:', error);
      let errorMessage = 'An unexpected error occurred';

      if (error instanceof Error) {
        console.error('Full error details:', {
          name: error.name,
          message: error.message,
          stack: error.stack,
          cause: error.cause
        });
        errorMessage = error.message;
      } else if (typeof error === 'string') {
        errorMessage = error;
      }

      const cleanMessage = errorMessage.replace(' (Check browser console for details)', '');
      const isOverloadedError = cleanMessage.includes('🦢');

      setError(
        <div className="space-y-2">
          <div className="font-medium">
            {isOverloadedError ? (
              <span className="flex items-center gap-2">{cleanMessage}</span>
            ) : (
              cleanMessage
            )}
          </div>
        </div>
      );
    } finally {
      setIsLoading(false);
      setAbortController(null);
    }
  };

  // Add new function for submitting singles count and generating schedule
  const handleSinglesSubmit = async () => {
    console.log('Submitting singles count:', cadenceForm);

    // First add the user's singles count as a message
    const singlesInputMessage = {
      role: 'user' as const,
      content: cadenceForm.singlesCount.toString(),
      timestamp: new Date().toISOString()
    };
    setMessages((prev) => [...prev, singlesInputMessage]);

    // Create the cadence request message
    const cadenceRequestMessage = {
      role: 'user' as const,
      content: `Plan a cadence for a ${cadenceForm.productType?.toLowerCase()} with release date "${cadenceForm.releaseDate}"${cadenceForm.singlesCount ? ` and ${cadenceForm.singlesCount} singles` : ''}`,
      timestamp: new Date().toISOString()
    };
    setMessages((prev) => [...prev, cadenceRequestMessage]);

    setIsLoading(true);
    setError(null);

    const controller = new AbortController();
    setAbortController(controller);

    try {
      // First, ensure we have a conversation
      const initResponse = await fetch('/ai/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        body: JSON.stringify({
          question: 'Hi Atlas',
          model: form.data.model,
          context: JSON.stringify(form.data.context)
        }),
        signal: controller.signal,
        credentials: 'same-origin'
      });

      if (!initResponse.ok) {
        throw new Error('Failed to initialize conversation');
      }

      const initData = await initResponse.json();
      const newConversationId = initData.conversation_id;

      if (!newConversationId) {
        throw new Error('No conversation ID received');
      }

      setConversationId(newConversationId);

      // Now make the actual cadence request
      const requestData = {
        question: cadenceRequestMessage.content,
        model: form.data.model,
        context: JSON.stringify({
          ...form.data.context,
          productType: cadenceForm.productType,
          releaseDate: cadenceForm.releaseDate,
          singlesCount: cadenceForm.singlesCount,
          artist: {
            ...form.data.context.artist,
            id: pageProps.artist?.id
          }
        }),
        conversation_id: newConversationId
      };

      console.log('Sending cadence request with data:', requestData);

      const response = await fetch('/ai/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        body: JSON.stringify(requestData),
        signal: controller.signal,
        credentials: 'same-origin'
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.error || errorJson.message || 'Unknown error occurred');
        } catch (e) {
          throw new Error(errorText);
        }
      }

      const data = await response.json();
      console.log('Success response:', data);

      // Create a title for the conversation
      const title =
        requestData.question.length > 30
          ? `${requestData.question.substring(0, 30)}...`
          : requestData.question;
      updateConversationsList(cadenceRequestMessage, newConversationId, title);

      // Add the AI response
      const aiMessage = {
        role: 'assistant' as const,
        content: data.answer,
        timestamp: new Date().toISOString(),
        response: {
          ...data,
          state: {
            ...(data.state || {}),
            current_step: 'schedule_generated',
            is_active: true,
            schedule_generated: true,
            product_type: cadenceForm.productType,
            release_date: cadenceForm.releaseDate,
            singles_count: cadenceForm.singlesCount
          }
        }
      };
      setMessages((prev) => [...prev, aiMessage]);

      setIsLoading(false);
    } catch (error) {
      console.error('Error submitting cadence plan:', error);
      // Remove both messages we added
      setMessages((prev) => prev.slice(0, -2));
      setError(
        <div>
          Failed to submit cadence plan. Error:{' '}
          {error instanceof Error ? error.message : 'Unknown error'}
        </div>
      );
      setIsLoading(false);
    } finally {
      setAbortController(null);
    }
  };

  // Rename and simplify the existing handleCadenceSubmit to handleConfirmSchedule
  const handleConfirmSchedule = async () => {
    console.log('Creating products from schedule...');
    setIsLoading(true);
    setError(null);

    try {
      // Get the last assistant message that contains the schedule
      const lastScheduleMessage = [...messages]
        .reverse()
        .find((m) => m.role === 'assistant' && m.response?.state?.schedule_generated);

      if (!lastScheduleMessage) {
        throw new Error('No schedule data found. Please generate a schedule first.');
      }

      // Try to get raw schedule from different possible locations
      const rawSchedule =
        lastScheduleMessage.response?.metadata?.raw_schedule ||
        lastScheduleMessage.response?.metadata?.metadata?.raw_schedule;

      if (!rawSchedule) {
        console.error('Response structure:', lastScheduleMessage.response);
        throw new Error(
          'No schedule data found in the response. Please try generating the schedule again.'
        );
      }

      console.log('Found schedule data:', rawSchedule);

      // Create products from the schedule
      const createProductsResponse = await createProductsFromSchedule(rawSchedule);
      console.log('Products created:', createProductsResponse);

      // Add success message
      const successMessage = {
        role: 'assistant' as const,
        content:
          "✨ Great! I've created all the products and timeline events for your release schedule. You can now find them in your products list.",
        timestamp: new Date().toISOString(),
        response: {
          answer:
            "✨ Great! I've created all the products and timeline events for your release schedule. You can now find them in your products list.",
          timestamp: new Date().toISOString(),
          state: {
            current_step: 'schedule_generated',
            is_active: true,
            schedule_generated: true,
            product_type: lastScheduleMessage?.response?.state?.product_type,
            release_date: lastScheduleMessage?.response?.state?.release_date,
            singles_count: lastScheduleMessage?.response?.state?.singles_count
          }
        }
      };
      setMessages((prev) => [...prev, successMessage]);

      // Reset form
      setCadenceForm({
        productType: null,
        releaseDate: null,
        singlesCount: 0
      });

      setIsLoading(false);
    } catch (error) {
      console.error('Error creating products:', error);
      setError(
        <div>
          Failed to create products. Error:{' '}
          {error instanceof Error ? error.message : 'Unknown error'}
        </div>
      );
      setIsLoading(false);
    }
  };

  // Add function to create products from schedule
  const createProductsFromSchedule = async (schedule: any) => {
    try {
      // Log the data we're sending
      console.log('Artist ID:', schedule.artist.hash_id);
      console.log('Full schedule:', schedule);

      const requestData = {
        artist_id: schedule.artist.hash_id, // Use the artist hash_id from the schedule
        schedule
      };

      console.log('Sending request data:', requestData);

      const response = await fetch('/ai/cadence/create-products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        body: JSON.stringify(requestData),
        credentials: 'same-origin'
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        try {
          const errorData = JSON.parse(errorText);
          throw new Error(errorData.error || 'Failed to create products');
        } catch (e) {
          throw new Error(`Server error: ${errorText}`);
        }
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating products:', error);
      throw error;
    }
  };

  // Add back the product type selection handler
  const handleProductTypeSelect = async (type: 'Album' | 'EP') => {
    setCadenceForm((prev) => ({ ...prev, productType: type }));

    // Add the user's selection as a message
    const newUserMessage = {
      role: 'user' as const,
      content: type,
      timestamp: new Date().toISOString()
    };
    setMessages((prev) => [...prev, newUserMessage]);

    // Add the assistant's response for the release date question
    const timestamp = new Date().toISOString();
    const content = `When would you like to release this ${type.toLowerCase()}?`;
    const newAssistantMessage: Message = {
      role: 'assistant',
      content,
      timestamp,
      response: {
        answer: content,
        timestamp,
        state: {
          current_step: 'release_date',
          is_active: true,
          product_type: type
        }
      }
    };
    setMessages((prev) => [...prev, newAssistantMessage]);
  };

  // Add back the release date submission handler
  const handleReleaseDateSubmit = () => {
    if (!cadenceForm.releaseDate) {
      setError(<div>Please enter a release date.</div>);
      return;
    }

    // Add the user's date input as a message
    const newUserMessage = {
      role: 'user' as const,
      content: cadenceForm.releaseDate,
      timestamp: new Date().toISOString()
    };
    setMessages((prev) => [...prev, newUserMessage]);

    // Add the assistant's response for the singles count question
    const timestamp = new Date().toISOString();
    const content = `How many singles would you like to release before the ${cadenceForm.productType?.toLowerCase()}?`;
    const newAssistantMessage: Message = {
      role: 'assistant',
      content,
      timestamp,
      response: {
        answer: content,
        timestamp,
        state: {
          current_step: 'singles_count',
          is_active: true,
          product_type: cadenceForm.productType,
          release_date: cadenceForm.releaseDate
        }
      }
    };
    setMessages((prev) => [...prev, newAssistantMessage]);
  };

  // Add new state for requesting changes
  const [isRequestingChanges, setIsRequestingChanges] = useState(false);
  const [changeRequest, setChangeRequest] = useState('');

  // Add handler for initiating change request
  const handleRequestChanges = () => {
    setIsRequestingChanges(true);
    setChangeRequest('');
  };

  // Add handler for submitting change request
  const handleSubmitChanges = async () => {
    if (!changeRequest.trim()) {
      setError(<div>Please enter your requested changes.</div>);
      return;
    }

    // Get the last schedule message
    const lastScheduleMessage = [...messages]
      .reverse()
      .find((m) => m.role === 'assistant' && m.response?.state?.schedule_generated);

    if (!lastScheduleMessage || !lastScheduleMessage.response?.metadata?.raw_schedule) {
      setError(<div>Could not find the previous schedule to modify.</div>);
      return;
    }

    const state = lastScheduleMessage.response.state;
    if (!state?.product_type) {
      setError(<div>Product type is missing from the schedule data.</div>);
      return;
    }

    setIsLoading(true);
    setError(null);
    setIsRequestingChanges(false);

    const controller = new AbortController();
    setAbortController(controller);

    try {
      const requestData = {
        artist_id: props.artist.id,
        previous_schedule: lastScheduleMessage.response.metadata.raw_schedule,
        changes: changeRequest,
        follow_up_question: changeRequest,
        context: {
          product_type: state.product_type,
          release_date: state.release_date ?? null,
          singles_count: state.singles_count ?? null,
          artist: props.artist,
          follow_up_question: changeRequest
        }
      };

      // Add detailed logging
      console.log('Change request value:', changeRequest);
      console.log('Full request data being sent:', JSON.stringify(requestData, null, 2));

      const response = await fetch('/ai/cadence/update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN':
            document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
          Accept: 'application/json'
        },
        body: JSON.stringify(requestData),
        signal: controller.signal
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response from server:', errorText);
        try {
          const errorJson = JSON.parse(errorText);
          throw new Error(errorJson.error || errorJson.message || 'Unknown error occurred');
        } catch (e) {
          throw new Error(errorText);
        }
      }

      const data = await response.json();
      console.log('Success response:', data);

      // Add the user's change request as a message
      const changeRequestMessage = {
        role: 'user' as const,
        content: `Please update the schedule with these changes: ${changeRequest}`,
        timestamp: new Date().toISOString()
      };
      setMessages((prev) => [...prev, changeRequestMessage]);

      // Add the AI response with the updated schedule
      const aiMessage = {
        role: 'assistant' as const,
        content: data.schedule || data.answer,
        timestamp: new Date().toISOString(),
        response: {
          ...data,
          metadata: {
            ...data.metadata,
            raw_schedule: data.raw_schedule || data.schedule
          },
          state: {
            current_step: 'schedule_generated',
            is_active: true,
            schedule_generated: true,
            product_type: state.product_type,
            release_date: state.release_date,
            singles_count: state.singles_count
          }
        }
      };
      setMessages((prev) => [...prev, aiMessage]);

      setChangeRequest('');
      setIsLoading(false);
    } catch (error) {
      console.error('Error submitting changes:', error);
      setError(
        <div>
          Failed to update schedule. Error:{' '}
          {error instanceof Error ? error.message : 'Unknown error'}
        </div>
      );
      setIsLoading(false);
      setIsRequestingChanges(false);
    } finally {
      setAbortController(null);
    }
  };

  return (
    <>
      <DrawerHeader
        title={
          <div className="flex items-center gap-1">
            <Icon icon="quill" className="w-4 h-4 text-gray-40" />
            <span>Atlas</span>
          </div>
        }
        onClick={onClose}
        actions={
          <div className="flex items-center gap-2">
            <ButtonV2
              variant="ghost"
              prefixIcon="plus"
              size="xs"
              iconOnly
              onClick={() => {
                setMessages([]);
                setConversationId(null);
                setError(null);
              }}
            />
            <ConversationsDropdown
              conversations={conversations}
              onSelect={handleConversationSelect}
              onEdit={handleConversationEdit}
              onDelete={handleConversationDelete}
              isLoading={isLoadingConversations}
              error={conversationsError || undefined}
              onRetry={() => {
                setConversationsError(null);
                fetchConversations();
              }}
              onNewChat={() => {
                setMessages([]);
                setConversationId(null);
                setError(null);
              }}
              currentConversationId={conversationId}
            />
          </div>
        }
      />
      <DrawerBody className="flex flex-col h-full overflow-y-auto">
        <div className="h-full p-4 space-y-4">
          {error && (
            <div className="bg-red-50 border border-red-100 text-red-600 px-3 py-2 rounded text-xs">
              {error}
            </div>
          )}

          {!messages.length && !error && (
            <div className="flex flex-col items-center justify-center h-full text-center text-gray-400 space-y-4">
              <p className="text-xs pb-2">{welcomeMessage}</p>
              <div className="flex flex-col gap-2">
                {/* Show contextual "Tell me about" button based on page type */}
                {pageProps.artist && (
                  <ButtonV2
                    variant="outlined"
                    size="sm"
                    prefixIcon="artist-single"
                    onClick={() => submitQuestion('Tell me about this artist')}
                  >
                    Tell me about this artist
                  </ButtonV2>
                )}
                {pageProps.product && (
                  <ButtonV2
                    variant="outlined"
                    size="sm"
                    prefixIcon="product-digital"
                    onClick={() => submitQuestion('Tell me about this product')}
                  >
                    Tell me about this product
                  </ButtonV2>
                )}
                {pageProps.video && (
                  <ButtonV2
                    variant="outlined"
                    size="sm"
                    prefixIcon="video"
                    onClick={() => submitQuestion('Tell me about this video')}
                  >
                    Tell me about this video
                  </ButtonV2>
                )}
                {pageProps.soundRecording && (
                  <ButtonV2
                    variant="outlined"
                    size="sm"
                    prefixIcon="music-note"
                    onClick={() => submitQuestion('Tell me about this sound recording')}
                  >
                    Tell me about this recording
                  </ButtonV2>
                )}

                {/* Show community artists button if on artist page */}
                {pageProps.artist?.community && (
                  <ButtonV2
                    variant="outlined"
                    size="sm"
                    prefixIcon="communities"
                    onClick={() => submitQuestion('Show me other artists in this community')}
                  >
                    Other artists in {pageProps.artist.community.display_name}
                  </ButtonV2>
                )}

                {/* Always show cadence planning button */}
                <ButtonV2
                  variant="outlined"
                  size="sm"
                  prefixIcon="calendar"
                  onClick={() => {
                    // Add the assistant's initial cadence planning message
                    const timestamp = new Date().toISOString();
                    const content =
                      'To begin planning your release schedule, please select whether this will be an Album or EP campaign.';
                    const newAssistantMessage: Message = {
                      role: 'assistant',
                      content,
                      timestamp,
                      response: {
                        answer: content,
                        timestamp,
                        state: {
                          current_step: 'product_type',
                          is_active: true,
                          schedule_generated: false // Explicitly set this to false at the start
                        }
                      }
                    };
                    setMessages((prev) => [...prev, newAssistantMessage]);
                  }}
                >
                  Plan a Cadence
                </ButtonV2>
              </div>
            </div>
          )}

          {messages.map((message, index) => {
            return (
              <div key={index}>
                {/* User Question */}
                {message.role === 'user' && (
                  <div className="bg-gray-100 p-3 rounded-lg text-right">
                    <div className="text-xs leading-relaxed">{message.content}</div>
                  </div>
                )}

                {/* Loading Indicator */}
                {index === messages.length - 1 &&
                  message.role === 'user' &&
                  isLoading &&
                  (!isInCadencePlanningStep(messages) ||
                    (message.content.startsWith('Plan a cadence for a') &&
                      !message.content.match(/^\d+$/))) && (
                    <div className="flex items-center gap-2 mt-8 mb-4">
                      <div className="flex items-center gap-[2px]">
                        <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-1"></div>
                        <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-2"></div>
                        <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-3"></div>
                        <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-4"></div>
                      </div>
                      <span className="text-xs italic text-gray-40">Atlas is thinking...</span>
                    </div>
                  )}

                {/* AI Response + Debug Info */}
                {message.role === 'assistant' && message.response && (
                  <div className="prose prose-sm max-w-none">
                    <div className="text-xs leading-relaxed space-y-1">
                      {((message.response.response || message.response.answer) ?? '')
                        .split('\n')
                        .map((line: string, i: number) => (
                          <div key={i} className={`text-xs leading-relaxed ${i > 0 ? 'mt-2' : ''}`}>
                            <ReactMarkdown
                              components={{
                                pre: ({ children }) => (
                                  <pre className="text-xs bg-gray-100 rounded p-2 mb-2 overflow-x-auto">
                                    {children}
                                  </pre>
                                ),
                                a: ({ href, children }) => (
                                  <Link href={href || '#'} className="text-xs">
                                    {children}
                                  </Link>
                                )
                              }}
                            >
                              {line}
                            </ReactMarkdown>
                          </div>
                        ))}
                    </div>

                    {/* Sources Section */}
                    {message.response.debug?.tools_used &&
                      message.response.debug.tools_used.length > 0 && (
                        <div className="mt-4 border-t border-gray-100 pt-4">
                          <div className="text-xs text-gray-60">
                            <h3 className="font-semibold mb-2">Sources</h3>
                            <ol className="list-decimal pl-4 space-y-1">
                              {message.response.debug.tools_used.map((tool, index) => (
                                <li key={index} className="text-xs">
                                  <span className="font-medium">{tool.name}</span>
                                  <span className="text-gray-400"> - {tool.purpose}</span>
                                </li>
                              ))}
                            </ol>
                          </div>
                        </div>
                      )}

                    <div className="text-xs text-gray-50 mt-2">
                      Answered at{' '}
                      {message.response.timestamp
                        ? new Date(message.response.timestamp).toLocaleString()
                        : 'Unknown time'}
                    </div>

                    {/* Show Confirm Schedule button only when schedule is generated and no success message exists in conversation */}
                    {message.response.state?.schedule_generated &&
                      !message.content.includes("Great! I've created all the products") &&
                      !messages.some((m) =>
                        m.content.includes("Great! I've created all the products")
                      ) && (
                        <div className="mt-4 space-y-4">
                          {isRequestingChanges ? (
                            <div className="space-y-2">
                              <FormTextInput
                                value={changeRequest}
                                onChange={(value: string) => setChangeRequest(value)}
                                placeholder="e.g., 'Move the first single closer to the album release' or 'Add another single between March and April'"
                                className="w-full"
                                inputClassName="text-xs"
                                size="sm"
                                label=""
                                variant="textarea"
                                rows={3}
                              />
                              <div className="flex gap-2 justify-end">
                                <ButtonV2
                                  variant="ghost"
                                  size="sm"
                                  onClick={() => setIsRequestingChanges(false)}
                                >
                                  Cancel
                                </ButtonV2>
                                <ButtonV2
                                  variant="filled"
                                  size="sm"
                                  onClick={handleSubmitChanges}
                                  disabled={!changeRequest.trim()}
                                >
                                  Submit Changes 🔄
                                </ButtonV2>
                              </div>
                            </div>
                          ) : (
                            <div className="flex gap-2">
                              <ButtonV2
                                variant="outlined"
                                size="sm"
                                onClick={handleRequestChanges}
                                className="flex-1"
                              >
                                Request Changes 📝
                              </ButtonV2>
                              <ButtonV2
                                variant="filled"
                                size="sm"
                                onClick={() => handleConfirmSchedule()}
                                className="flex-1"
                              >
                                Confirm Schedule 🚀
                              </ButtonV2>
                            </div>
                          )}
                        </div>
                      )}

                    {/* Debug Panel - Only show if not in cadence planning mode */}
                    {message.response.debug &&
                      !isInCadencePlanningStep(messages) &&
                      message.response.debug.tools_used && (
                        <div className="mt-4 border-t border-gray-100 pt-4">
                          <Accordion
                            title="Debug Info"
                            defaultOpen={false}
                            className="!bg-transparent hover:!bg-transparent"
                            panelClassName="!pt-2"
                          >
                            {message.response.debug && (
                              <div className="text-xs space-y-2">
                                {/* Model and Usage Section */}
                                <div className="bg-gray-80 rounded p-2">
                                  <div className="font-bold mb-2">Model & Usage Information</div>
                                  <div className="space-y-2">
                                    {/* Model Info */}
                                    {message.response.debug.model && (
                                      <div className="border-b border-gray-100 pb-2">
                                        <div className="font-medium text-gray-600 mb-1">
                                          Model Details:
                                        </div>
                                        <div className="text-xs text-gray-600 grid gap-1">
                                          <div>Name: {message.response.debug.model.name}</div>
                                          {message.response.debug.model.version && (
                                            <div>
                                              Version: {message.response.debug.model.version}
                                            </div>
                                          )}
                                          {message.response.debug.model.provider && (
                                            <div>
                                              Provider: {message.response.debug.model.provider}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}

                                    {/* Usage Stats */}
                                    {message.response.debug.usage && (
                                      <div>
                                        <div className="font-medium text-gray-600 mb-1">
                                          Usage Statistics:
                                        </div>
                                        <div className="text-xs text-gray-600 grid gap-1">
                                          <div>
                                            Prompt Tokens:{' '}
                                            {message.response.debug.usage.prompt_tokens}
                                          </div>
                                          <div>
                                            Completion Tokens:{' '}
                                            {message.response.debug.usage.completion_tokens}
                                          </div>
                                          <div>
                                            Total Tokens:{' '}
                                            {message.response.debug.usage.total_tokens}
                                          </div>
                                          {message.response.debug.usage.estimated_cost_usd !==
                                            undefined &&
                                            message.response.debug.usage.estimated_cost_usd !==
                                              null && (
                                              <div className="mt-1 text-gray-400">
                                                Estimated Cost: ${' '}
                                                {Number(
                                                  message.response.debug.usage.estimated_cost_usd
                                                ).toFixed(6)}{' '}
                                                USD
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                {/* Full Response */}
                                <div className="bg-gray-80 rounded p-2">
                                  <div className="font-bold mb-1">Full Response:</div>
                                  <div className="text-xs text-gray-600 whitespace-pre-wrap">
                                    {((message.response.response || message.response.answer) ?? '')
                                      .replace(/<thinking>[\s\S]*?<\/thinking>/g, '')
                                      .replace(
                                        /<search_quality_reflection>[\s\S]*?<\/search_quality_reflection>/g,
                                        ''
                                      )
                                      .replace(
                                        /<search_quality_score>.*?<\/search_quality_score>/g,
                                        ''
                                      )
                                      .replace(/<\/?result>/g, '')
                                      .replace(/<\/?response>/g, '')
                                      .split('\n')
                                      .filter((line) => line.trim())
                                      .join('\n')
                                      .trim()}
                                  </div>
                                </div>

                                {/* Debug Info */}
                                {(message.response.debug?.reflection ||
                                  message.response.answer?.match(
                                    /<thinking>([\s\S]*?)<\/thinking>/
                                  ) ||
                                  message.response.answer?.match(
                                    /<search_quality_reflection>([\s\S]*?)<\/search_quality_reflection>/
                                  )) && (
                                  <div className="bg-gray-80 rounded p-2">
                                    <div className="font-bold mb-1">Reflection:</div>
                                    <div className="text-xs text-gray-600 whitespace-pre-wrap">
                                      {(
                                        message.response.debug?.reflection ||
                                        message.response.answer?.match(
                                          /<thinking>([\s\S]*?)<\/thinking>/
                                        )?.[1] ||
                                        message.response.answer?.match(
                                          /<search_quality_reflection>([\s\S]*?)<\/search_quality_reflection>/
                                        )?.[1]
                                      )?.trim()}
                                    </div>
                                  </div>
                                )}

                                {/* Search Quality Score */}
                                {(message.response.debug?.search_quality_score ||
                                  message.response.answer?.match(
                                    /<search_quality_score>(.*?)<\/search_quality_score>/
                                  )) && (
                                  <div className="bg-gray-80 rounded p-2">
                                    <div className="font-bold mb-1">Search Quality Score:</div>
                                    <div className="text-xs text-gray-600">
                                      {message.response.debug?.search_quality_score ||
                                        message.response.answer?.match(
                                          /<search_quality_score>(.*?)<\/search_quality_score>/
                                        )?.[1]}
                                    </div>
                                  </div>
                                )}

                                {/* Prompt */}
                                <div className="bg-gray-80 rounded p-2">
                                  <div className="font-bold mb-1">Prompt:</div>
                                  <pre className="whitespace-pre-wrap font-mono text-[10px] leading-normal">
                                    {message.response.debug.prompt}
                                  </pre>
                                </div>

                                {/* Context */}
                                <div className="bg-gray-80 rounded p-2">
                                  <div className="font-bold mb-1">Context:</div>
                                  <pre className="whitespace-pre-wrap font-mono text-[10px] leading-normal">
                                    {JSON.stringify(message.response.debug.context, null, 2)}
                                  </pre>
                                </div>
                              </div>
                            )}
                          </Accordion>
                        </div>
                      )}
                  </div>
                )}
              </div>
            );
          })}

          {/* Add product type selection buttons as a user message-like element */}
          {isAtProductTypeSelection(messages) && (
            <div className="bg-gray-100 p-3 rounded-lg text-right">
              <div className="flex gap-2 justify-end">
                <ButtonV2
                  onClick={() => handleProductTypeSelect('Album')}
                  variant="outlined"
                  size="sm"
                >
                  Album
                </ButtonV2>
                <ButtonV2
                  onClick={() => handleProductTypeSelect('EP')}
                  variant="outlined"
                  size="sm"
                >
                  EP
                </ButtonV2>
              </div>
            </div>
          )}

          {/* Replace the date picker section with text input */}
          {isAtReleaseDateSelection(messages) && (
            <div className="bg-gray-100 p-3 rounded-lg text-right">
              <div className="flex flex-col gap-2 items-end">
                <div className="flex gap-2 items-center">
                  <FormTextInput
                    value={cadenceForm.releaseDate || ''}
                    onChange={(value: string) =>
                      setCadenceForm((prev) => ({ ...prev, releaseDate: value }))
                    }
                    placeholder="e.g. June 15th, 2024"
                    className="w-48"
                    inputClassName="text-right text-xs"
                    size="sm"
                    label=""
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleReleaseDateSubmit();
                      }
                    }}
                  />
                  <ButtonV2 size="xs" variant="outlined" onClick={handleReleaseDateSubmit}>
                    Next
                  </ButtonV2>
                </div>
                <div className="text-xs text-gray-500">
                  Enter a release date in natural language
                </div>
              </div>
            </div>
          )}

          {/* Add singles count input */}
          {isAtSinglesCountSelection(messages) && (
            <div className="bg-gray-100 p-3 rounded-lg text-right">
              <div className="flex flex-col gap-2 items-end">
                <div className="flex gap-2 items-center">
                  <FormTextInput
                    type="number"
                    min="0"
                    value={cadenceForm.singlesCount.toString()}
                    onChange={(value: string) =>
                      setCadenceForm((prev) => ({
                        ...prev,
                        singlesCount: parseInt(value) || 0
                      }))
                    }
                    placeholder="e.g. 3"
                    className="w-24"
                    inputClassName="text-right text-xs"
                    size="sm"
                    label=""
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        handleSinglesSubmit();
                      }
                    }}
                  />
                  <ButtonV2 size="xs" variant="outlined" onClick={handleSinglesSubmit}>
                    Submit
                  </ButtonV2>
                </div>
                <div className="text-xs text-gray-500">Enter the number of singles to release</div>
              </div>
            </div>
          )}

          {/* Add loading indicator during cadence submission - only shown when not showing indicator in the message list */}
          {shouldShowStandaloneLoadingIndicator && (
            <div className="flex items-center gap-2 mt-8 mb-4">
              <div className="flex items-center gap-[2px]">
                <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-1"></div>
                <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-2"></div>
                <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-3"></div>
                <div className="w-[2px] h-4 bg-gray-40 origin-center animate-soundbar-4"></div>
              </div>
              <span className="text-xs italic text-gray-40">Atlas is thinking...</span>
            </div>
          )}

          {/* Bottom spacer */}
          <div className="h-32"></div>
        </div>
      </DrawerBody>

      {/* Only show footer if not in cadence planning mode and not loading during cadence planning */}
      {!isInCadencePlanningStep(messages) &&
        !(isLoading && messages.some((m) => m.content?.includes('CADENCE_PLANNING_MODE'))) && (
          <div className="border-t border-gray-80 bg-white">
            <form ref={formRef} onSubmit={handleSubmit} className="p-4">
              <div className="[&_*]:!shadow-none [&_*]:ring-0 [&_*]:focus:!ring-0 [&_*]:focus-within:!shadow-none">
                <TextInput
                  name="question"
                  value={form.data.question}
                  onChange={(value: string) => form.setData('question', value)}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      handleSubmit(e);
                    }
                  }}
                  placeholder="Ask Atlas about the current page or data..."
                  autoFocus
                  variant="textarea"
                  size="sm"
                  focusRing={false}
                  disabled={isLoading}
                  className="resize-none !p-3 min-h-[80px] max-h-[120px] w-full !border !border-gray-80 rounded-lg !outline-none focus:!outline-none focus:!border-gray-10 active:!border-gray-90 bg-white disabled:bg-gray-50 disabled:cursor-not-allowed"
                />
              </div>

              <div className="flex items-center justify-between mt-2">
                {isLoading && (
                  <div className="flex items-center gap-2 text-xs">
                    <span className="animate-pulse text-gray-30">Generating response...</span>
                  </div>
                )}
                <div className="ml-auto flex items-center gap-2">
                  {/* Usage Stats Popover */}
                  <Popover className="relative">
                    <Popper
                      reference={
                        <ButtonV2
                          as={Popover.Button}
                          variant="ghost"
                          size="sm"
                          prefixIcon="chart-bars"
                          iconOnly
                        />
                      }
                      middleware={[dropdownOffset()]}
                      placement="bottom-end"
                    >
                      <Popover.Panel>
                        <Dropdown>
                          <div className="p-3 min-w-[280px]">
                            <div className="space-y-4">
                              {/* Total Usage Section */}
                              <div className="space-y-2">
                                <div className="flex items-center justify-between text-xs">
                                  <span className="text-gray-600">Total Token Usage</span>
                                  <span
                                    className={`font-medium ${
                                      getTokenUsageStatus() === 'critical'
                                        ? 'text-red-600'
                                        : getTokenUsageStatus() === 'warning'
                                          ? 'text-amber-600'
                                          : 'text-gray-600'
                                    }`}
                                  >
                                    {getTotalTokens().toLocaleString()} /{' '}
                                    {TOKEN_LIMIT.toLocaleString()}
                                  </span>
                                </div>
                                <div className="h-1.5 bg-gray-100 rounded-full overflow-hidden">
                                  <div
                                    className={`h-full transition-all ${
                                      getTokenUsageStatus() === 'critical'
                                        ? 'bg-red-500'
                                        : getTokenUsageStatus() === 'warning'
                                          ? 'bg-amber-500'
                                          : 'bg-blue-500'
                                    }`}
                                    style={{
                                      width: `${Math.min(getTokenUsagePercentage(), 100)}%`
                                    }}
                                  />
                                </div>
                                {getTokenUsageStatus() !== 'normal' && (
                                  <div
                                    className={`text-xs ${
                                      getTokenUsageStatus() === 'critical'
                                        ? 'text-red-600'
                                        : 'text-amber-600'
                                    }`}
                                  >
                                    {getTokenUsageStatus() === 'critical'
                                      ? '⚠️ Token limit nearly reached. Consider starting a new conversation.'
                                      : '⚠️ Approaching token limit'}
                                  </div>
                                )}
                              </div>

                              {/* Model-Specific Usage Section */}
                              <div className="space-y-3 border-t border-gray-100 pt-3">
                                <div className="text-xs font-medium text-gray-600">
                                  Model-Specific Usage
                                </div>

                                {/* Claude Usage */}
                                {modelMetrics.atlas.prompt_tokens > 0 && (
                                  <div className="space-y-1">
                                    <div className="flex items-center justify-between text-xs">
                                      <span className="text-gray-500">claude 3 opus</span>
                                      <span className="font-medium text-gray-600">
                                        {modelMetrics.atlas.prompt_tokens.toLocaleString()} tokens
                                      </span>
                                    </div>
                                    <div className="text-xs text-gray-400 text-right">
                                      ${modelMetrics.atlas.estimated_cost_usd.toFixed(6)} USD
                                    </div>
                                  </div>
                                )}

                                {/* GPT-4 Usage */}
                                {modelMetrics['gpt-4'].prompt_tokens > 0 && (
                                  <div className="space-y-1">
                                    <div className="flex items-center justify-between text-xs">
                                      <span className="text-gray-500">gpt-4 turbo</span>
                                      <span className="font-medium text-gray-600">
                                        {modelMetrics['gpt-4'].prompt_tokens.toLocaleString()}{' '}
                                        tokens
                                      </span>
                                    </div>
                                    <div className="text-xs text-gray-400 text-right">
                                      ${modelMetrics['gpt-4'].estimated_cost_usd.toFixed(6)} USD
                                    </div>
                                  </div>
                                )}
                              </div>

                              {/* Total Cost Section */}
                              <div className="flex items-center justify-between text-xs pt-2 mt-2 border-t border-gray-100">
                                <span className="text-gray-600">Total Cost</span>
                                <span className="font-medium text-gray-600">
                                  ${getTotalCost().toFixed(6)} USD
                                </span>
                              </div>
                            </div>
                          </div>
                        </Dropdown>
                      </Popover.Panel>
                    </Popper>
                  </Popover>

                  {/* Model Selector Popover */}
                  <Popover className="relative">
                    {({ open }) => (
                      <Popper
                        reference={
                          <ButtonV2
                            as={Popover.Button}
                            variant="ghost"
                            size="sm"
                            prefixIcon={open ? 'chevron-up' : 'chevron-down'}
                          >
                            {modelOptions.find((opt) => opt.id === selectedModel)?.name}
                          </ButtonV2>
                        }
                        middleware={[dropdownOffset()]}
                        placement="bottom-end"
                        className="z-[60]"
                      >
                        <Popover.Panel>
                          <Dropdown className="z-[60]">
                            <div className="py-1 min-w-[120px]">
                              {modelOptions.map((option) => (
                                <button
                                  key={option.id}
                                  onClick={() => {
                                    setSelectedModel(option.id);
                                  }}
                                  className="w-full text-left px-3 py-1.5 hover:bg-gray-100 focus:outline-none text-xs"
                                >
                                  {option.name}
                                </button>
                              ))}
                            </div>
                          </Dropdown>
                        </Popover.Panel>
                      </Popper>
                    )}
                  </Popover>
                  {isLoading ? (
                    <ButtonV2
                      type="button"
                      onClick={handleStop}
                      size="sm"
                      variant="ghost"
                      prefixIcon="control-pause"
                      className="text-red-600 hover:text-red-700 cursor-pointer"
                    >
                      Stop
                    </ButtonV2>
                  ) : (
                    <ButtonV2
                      type="submit"
                      disabled={!form.data.question.trim()}
                      size="sm"
                      variant="filled"
                      prefixIcon="sparkle"
                    >
                      Ask Atlas
                    </ButtonV2>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
    </>
  );
}
