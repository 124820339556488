import clsx from 'clsx';
import React from 'react';
import { withTheme } from '../theme/withTheme';
import { ActionTextBase, ActionTextSm } from '../typography/actions/ActionText';

interface Props {
  children: React.ReactNode;
  id?: string;
  hasError?: boolean;
  hasSuccess?: boolean;
  isDisabled?: boolean;
  onDark?: boolean;
  size?: 'sm' | 'md' | '2xl';
  inline?: boolean;
}

export const Label = withTheme<Props>(
  ({
    children,
    id,
    hasError = false,
    hasSuccess = false,
    onDark = false,
    isDisabled = false,
    size,
    inline
  }: Props) => {
    const themeVariant = onDark ? 'dark' : 'light';
    const Component = size === 'sm' ? ActionTextSm : ActionTextBase;
    return (
      <Component
        theme={themeVariant}
        as="label"
        isInline={inline}
        htmlFor={id}
        className={clsx(
          'text-black dk:text-white',
          hasError && 'text-red-10 text-shadow-none dk:text-functional-on-dark-error',
          hasSuccess && 'text-green-10 text-shadow-none dk:text-functional-on-light-success',
          isDisabled ? 'cursor-default text-gray-40' : 'cursor-pointer text-black dk:text-white'
        )}
      >
        {children}
      </Component>
    );
  }
);
