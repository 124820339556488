import { AIAssistantDrawer } from './AIAssistantDrawer';
import useDrawers from '@/hooks/useDrawers';

export function useAIAssistantDrawer() {
  const { openDrawer, closeDrawer } = useDrawers();

  function open() {
    const id = openDrawer({
      component: AIAssistantDrawer,
      props: {
        onClose: () => closeDrawer(id)
      }
    });
    return id;
  }

  return { open };
}
