import clsx from 'clsx';
import React, { useRef } from 'react';
import { Icon } from '../icon/Icon';
import { withTheme } from '../theme/withTheme';

export type TextVariant = 'textarea' | 'input';

export interface TextInputProps
  extends Omit<React.HTMLProps<HTMLInputElement>, 'onChange' | 'size' | 'prefix'> {
  hasError?: boolean;
  hasSuccess?: boolean;
  onDark?: boolean;
  variant?: TextVariant;
  size?: 'sm' | 'md' | '2xl';
  suffix?: (ctx: TextInputAffixProps) => React.ReactNode;
  prefix?: (ctx: TextInputAffixProps) => React.ReactNode;
  focusRing?: boolean;
  onChange?: (value: string) => void;
  onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export type TextInputAffixProps = Pick<
  TextInputProps,
  'hasError' | 'hasSuccess' | 'onDark' | 'variant' | 'size' | 'disabled' | 'value'
> & {
  affix?: 'prefix' | 'suffix';
};

export const TextInput = withTheme(
  ({
    type = 'text',
    value = '',
    hasError = false,
    hasSuccess = false,
    onDark = false,
    variant = 'input',
    size = 'md',
    focusRing = false,
    onChange,
    onClear,
    className,
    disabled,
    prefix,
    suffix,
    ...rest
  }: TextInputProps) => {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        onChange(e.target.value);
      }
    }

    function handleClear(e: React.MouseEvent<HTMLButtonElement>) {
      if (onClear) {
        onClear(e);
      }

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    const inputRef = useRef<HTMLInputElement>(null);
    const themeVariant = onDark ? 'dark' : 'light';
    const Component: any = variant;
    const noErrorOrSuccess = !hasError && !hasSuccess;

    return (
      <div
        theme={themeVariant}
        className={clsx(
          'flex w-full bg-interactive-on-light group/input h-full',
          variant === 'input' ? 'items-center' : 'items-end',
          size === '2xl'
            ? ['border-0', disabled && 'text-gray-60']
            : [
                noErrorOrSuccess && [
                  'border-interactive-on-light/50 dk:border-gray-20/25 dk:bg-interactive-on-dark'
                ],
                !focusRing &&
                  'focus-within:border-transparent focus-within:!bg-white focus-within:shadow-focus focus-within:dk:border-transparent focus-within:dk:!bg-transparent focus-within:dk:shadow-focus-reversed',
                size === 'sm' && 'rounded-sm border',
                size === 'md' && 'rounded-lg border-2',
                disabled &&
                  'border-gray-70/25 bg-transparent shadow-none dk:border-gray-30/25 dk:bg-transparent',
                hasError &&
                  'border-functional-on-light-error !bg-red-100 dk:border-functional-on-light-error dk:!bg-red-10',
                hasSuccess &&
                  'border-functional-on-light-success !bg-green-100 dk:border-functional-on-light-success dk:!bg-green-10'
              ]
        )}
      >
        {prefix ? (
          <div
            className={clsx(
              'shrink-0 flex items-center',
              hasSuccess && 'text-green-10 dk:!text-white',
              hasError && 'text-red-10 dk:!text-red-90',
              disabled && '!text-gray-40'
            )}
          >
            {prefix({
              affix: 'prefix',
              hasError,
              hasSuccess,
              onDark,
              variant,
              size,
              disabled,
              value
            })}
          </div>
        ) : null}

        <Component
          {...rest}
          ref={inputRef}
          theme={themeVariant}
          type={type}
          value={value ?? ''}
          onChange={handleChange}
          disabled={disabled}
          className={clsx(
            '!border-0 !bg-transparent p-0 shadow-none focus:outline-none ',
            focus('ring-0'),
            size === '2xl'
              ? [
                  'px-0 py-2 text-5xl/[72px] font-light tracking-tighter placeholder:text-gray-80',
                  'dk:text-white dk:placeholder:text-gray-20',
                  hasError && 'dk:text-red-80',
                  hasSuccess && 'dk:text-green-90',
                  disabled && 'text-gray-60 dk:text-gray-30'
                ]
              : [
                  size === 'sm' && 'rounded-sm p-2 text-xs -m-[1px]',
                  size === 'md' && 'rounded-md p-4 leading-5 -m-[2px]',
                  'text-black placeholder:text-gray-40',
                  'dk:text-white dk:placeholder:text-gray-30',
                  disabled && '!text-gray-40 ',
                  hasSuccess && 'dk:text-green-100'
                ],
            className
          )}
        />

        {suffix ? (
          <div
            className={clsx(
              'shrink-0 flex items-center',
              hasSuccess && 'text-green-10 dk:!text-white',
              hasError && 'text-red-10 dk:!text-red-90',
              disabled && '!text-gray-40'
            )}
          >
            {suffix({
              affix: 'suffix',
              hasError,
              hasSuccess,
              onDark,
              variant,
              size,
              disabled,
              value
            })}
          </div>
        ) : null}

        {onClear ? (
          <button
            onClick={handleClear}
            type="button"
            className={clsx([
              'z-10 flex h-full items-center rounded outline-none',
              !value && 'invisible'
            ])}
            tabIndex={-1}
            aria-hidden={true}
          >
            <Icon
              className="mr-4 h-5 w-5 text-amethyst dk:text-emerald"
              theme={themeVariant}
              icon="clear"
            />
          </button>
        ) : null}
      </div>
    );
  }
);
