import { ReactNode } from 'react';
import { BodyTextSm } from '../typography/body/BodyText';
import { ActionTextSm } from '../typography/actions/ActionText';
import clsx from 'clsx';
import { BaseAccordion, BaseAccordionProps } from './BaseAccordion';

export interface AccordionProps extends Omit<BaseAccordionProps, 'buttonContent'> {
  title?: ReactNode;
  defaultOpen?: boolean;
  secondaryInformation?: string;
  data?: string;
  value?: string;
  number?: string | number;
  icon?: 'caret' | 'chevron';
}

export function Accordion({
  className,
  panelClassName,
  buttonClassName,
  buttonContentClassName,
  title,
  defaultOpen,
  secondaryInformation,
  data,
  value,
  children,
  prefix,
  suffix,
  number,
  icon
}: AccordionProps) {
  return (
    <BaseAccordion
      buttonClassName={clsx('flex-1 flex justify-between focus:outline-none', buttonClassName)}
      defaultOpen={defaultOpen}
      panelClassName={clsx('pt-4', panelClassName)}
      suffix={suffix}
      buttonContentClassName={buttonContentClassName}
      prefix={prefix}
      icon={icon}
      className={clsx(
        className,
        'flex justify-between rounded-md bg-transparent [&:has(button[aria-expanded]:focus)]:shadow-focus p-1 hover:bg-gray-100'
      )}
      buttonContent={
        <>
          {number ? (
            <div className="text-gray-20 text-xs base-font-semibold uppercase tracking-wide !ml-1 !mr-2 min-w-6">
              {number}
            </div>
          ) : null}
          <div className="flex items-center divide-x divide-gray-90">
            {title ? (
              <div className="text-xs base-font-semibold tracking-wide text-gray-10 pr-1.5 leading-5">
                {title}
              </div>
            ) : null}
            {secondaryInformation && (
              <BodyTextSm className="text-gray-10 pl-1.5">{secondaryInformation}</BodyTextSm>
            )}
          </div>
          <div className="flex space-x-2">
            <BodyTextSm className="text-gray-10">
              {data}
              {data && value && ':'}
            </BodyTextSm>
            <ActionTextSm className="text-gray-10">{value}</ActionTextSm>
          </div>
        </>
      }
    >
      {children}
    </BaseAccordion>
  );
}
