import { Transition } from '@headlessui/react';
import clsx from 'clsx';

interface Props {
  show?: boolean;
  children: React.ReactNode;
}

export const ACCORDION_ANIMATION_DURATION = 'duration-[0.4s]';
export const ACCORDION_ANIMATION_EASING = 'ease-[cubic-bezier(0.16,1,0.3,1)]';

export function AccordionTransition({ show, children }: Props) {
  return (
    <Transition
      show={show}
      className="grid transition-grid-rows-template"
      enter={clsx(ACCORDION_ANIMATION_DURATION, ACCORDION_ANIMATION_EASING)}
      enterFrom="grid-rows-[0fr]"
      enterTo="grid-rows-[1fr]"
      leave={clsx(ACCORDION_ANIMATION_DURATION, ACCORDION_ANIMATION_EASING)}
      leaveFrom="grid-rows-[1fr]"
      leaveTo="grid-rows-[0fr]"
    >
      {children}
    </Transition>
  );
}
