import { Disclosure } from '@headlessui/react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { Icon } from '@/components/icon/Icon';
import {
  ACCORDION_ANIMATION_DURATION,
  ACCORDION_ANIMATION_EASING,
  AccordionTransition
} from '@/components/accordion/AccordionTransition';

export interface BaseAccordionProps {
  className?: string;
  panelClassName?: string;
  buttonClassName?: string;
  children?: ReactNode;
  defaultOpen?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  buttonContent?: ReactNode;
  buttonContentClassName?: string;
  icon?: 'caret' | 'chevron';
}

export function BaseAccordion({
  className,
  panelClassName,
  buttonClassName,
  children,
  defaultOpen,
  prefix,
  suffix,
  buttonContent,
  buttonContentClassName,
  icon = 'caret'
}: BaseAccordionProps) {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <div className={clsx(className, 'gap-1.5')}>
            {prefix || null}
            <Disclosure.Button aria-expanded={open} className={clsx(buttonClassName)}>
              <div className={clsx(buttonContentClassName, 'flex space-x-1.5 items-center')}>
                <Icon
                  icon={`${icon}-right`}
                  className={clsx(
                    'text-gray-40 h-4 w-4 flex-shrink-0 transition',
                    ACCORDION_ANIMATION_DURATION,
                    ACCORDION_ANIMATION_EASING,
                    open ? 'rotate-90' : null
                  )}
                />
                {buttonContent}
              </div>
            </Disclosure.Button>
            {suffix || null}
          </div>

          <AccordionTransition>
            <Disclosure.Panel static className="overflow-hidden">
              <div className={clsx('overflow-hidden', panelClassName)}>{children}</div>
            </Disclosure.Panel>
          </AccordionTransition>
        </>
      )}
    </Disclosure>
  );
}
