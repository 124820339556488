import React, { useState } from 'react';
import { withTheme } from '../theme/withTheme';
import { FormField } from './FormField';
import { TextInput, TextInputAffixProps, TextVariant } from './TextInput';
import { TextInputIcon } from './TextInputIcon';
import clsx from 'clsx';

interface CommonProps
  extends Omit<
    React.HTMLProps<HTMLInputElement>,
    'label' | 'onChange' | 'ref' | 'size' | 'prefix'
  > {
  variant?: TextVariant;
  label: React.ReactNode;
  value?: any;
  helpText?: React.ReactNode | null;
  error?: string | null;
  success?: string | null;
  className?: string;
  inputClassName?: string;
  onChange?: (value: string) => void;
  onClear?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  size?: 'sm' | 'md' | '2xl';
  prefix?: (ctx: TextInputAffixProps) => React.ReactNode;
  suffix?: (ctx: TextInputAffixProps) => React.ReactNode;
  focusRing?: boolean;
  inline?: boolean;
  autoFocus?: boolean;
}

export type FormTextInputProps = CommonProps;

export const FormTextInput = withTheme<FormTextInputProps>(
  ({
    label,
    value = '',
    error = null,
    success = null,
    helpText = null,
    variant = 'input',
    onChange,
    onClear,
    className,
    inputClassName,
    disabled,
    size,
    prefix,
    suffix,
    focusRing,
    inline,
    autoFocus,
    ...rest
  }: FormTextInputProps) => {
    const [isFocused, setIsFocused] = useState(autoFocus);

    const focusHandler = () => {
      setIsFocused(true);
    };

    const blurHandler = () => {
      setIsFocused(false);
    };

    if (!suffix) {
      const icon = error ? 'high-priority' : success ? 'check' : undefined;
      if (icon) {
        suffix = () => <TextInputIcon icon={icon} affix="suffix" />;
      }
    }

    return (
      <FormField
        inline={inline}
        isFocused={isFocused}
        label={label}
        error={error}
        success={success}
        helpText={helpText}
        className={className}
        isDisabled={disabled}
        size={size}
      >
        <TextInput
          focusRing={focusRing}
          variant={variant}
          disabled={disabled}
          onFocus={focusHandler}
          onBlur={blurHandler}
          onChange={onChange}
          onClear={onClear}
          hasError={!!error}
          hasSuccess={!!success}
          value={value}
          className={clsx([inputClassName, 'w-full'])}
          size={size}
          prefix={prefix}
          suffix={suffix}
          {...rest}
        />
      </FormField>
    );
  }
);
