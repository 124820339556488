import clsx, { ClassValue } from 'clsx';
import React from 'react';
import { Icon, IconName } from '../icon/Icon';
import { TextInputAffixProps } from '@/components/form/TextInput';

export const TextInputIcon = ({
  icon,
  onDark,
  size = 'md',
  variant,
  hasError,
  hasSuccess,
  disabled,
  className,
  affix = 'prefix'
}: TextInputAffixProps & {
  className?: ClassValue;
  icon: IconName;
}) => {
  return (
    <Icon
      theme={onDark ? 'dark' : 'light'}
      icon={icon}
      className={clsx(
        className,
        affix === 'prefix' && (size === 'md' ? 'ml-2' : 'ml-4'),
        affix === 'suffix' && (size === 'md' ? 'mr-2' : 'mr-4'),
        size === 'sm' && ['h-4 w-4 p-[1px]', variant === 'textarea' && 'mb-2'],
        size === 'md' && ['h-5 w-5 p-[1px]', variant === 'textarea' && 'mb-4'],
        size === '2xl' && 'h-[72px] w-[72px]',
        size === '2xl' && hasSuccess && 'text-green-20',
        size === '2xl' && hasError && 'text-red-20',
        size === '2xl' && disabled && '!text-gray-60'
      )}
    />
  );
};
