import { Popover } from '@headlessui/react';
import { ButtonV2 } from '@/components/button/ButtonV2';
import { format, isToday } from 'date-fns';
import { useState } from 'react';

interface Conversation {
  id: string;
  title: string;
  created_at: string;
  last_message?: string;
}

interface ConversationsDropdownProps {
  conversations: Conversation[];
  onSelect: (conversationId: string) => void;
  onEdit: (conversationId: string, newTitle: string) => void;
  onDelete: (conversationId: string) => void;
  isLoading?: boolean;
  error?: string;
  onRetry?: () => void;
  onNewChat?: () => void;
  currentConversationId?: string | null;
}

export function ConversationsDropdown({
  conversations,
  onSelect,
  onEdit,
  onDelete,
  isLoading = false,
  error,
  onRetry,
  onNewChat,
  currentConversationId
}: ConversationsDropdownProps) {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editTitle, setEditTitle] = useState('');

  const sortedConversations = [...conversations].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  );

  const handleEditStart = (conversation: Conversation, e: React.MouseEvent) => {
    e.stopPropagation();
    setEditingId(conversation.id);
    setEditTitle(conversation.title);
  };

  const handleEditSave = (e: React.FormEvent) => {
    e.preventDefault();
    if (editingId && editTitle.trim()) {
      onEdit(editingId, editTitle.trim());
      setEditingId(null);
    }
  };

  const handleEditCancel = () => {
    setEditingId(null);
    setEditTitle('');
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleEditCancel();
    }
  };

  const handleDelete = (conversationId: string, e: React.MouseEvent) => {
    e.stopPropagation();
    if (confirm('Are you sure you want to delete this conversation?')) {
      onDelete(conversationId);
    }
  };

  return (
    <Popover className="relative inline-block text-left">
      <ButtonV2 as={Popover.Button} size="xs" variant="ghost" prefixIcon="history" iconOnly />
      <Popover.Panel className="absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
        {({ close }) => (
          <div className="py-1 max-h-[400px] overflow-y-auto bg-white">
            <div className="px-4 py-2 border-b border-gray-90">
              <ButtonV2
                size="xs"
                onClick={() => {
                  onNewChat?.();
                  close();
                }}
                prefixIcon="plus"
                className="w-full"
              >
                New Chat
              </ButtonV2>
            </div>
            {isLoading ? (
              <div className="px-4 py-2 text-xs text-gray-700">Loading conversations...</div>
            ) : error ? (
              <div className="p-4 space-y-2">
                <div className="text-xs text-red-600">{error}</div>
                {onRetry && (
                  <ButtonV2 size="xs" onClick={onRetry} prefixIcon="refresh" className="w-full">
                    Retry
                  </ButtonV2>
                )}
              </div>
            ) : sortedConversations.length === 0 ? (
              <div className="px-4 py-2 text-xs text-gray-700">No conversations available</div>
            ) : (
              sortedConversations.map((conversation) => (
                <button
                  key={conversation.id}
                  onClick={() => {
                    if (!editingId) {
                      onSelect(conversation.id);
                      close();
                    }
                  }}
                  className="w-full text-left px-3 hover:bg-gray-100 focus:outline-none group"
                >
                  <div className="flex flex-col py-2">
                    <div className="flex justify-between items-center h-6">
                      {editingId === conversation.id ? (
                        <form
                          onSubmit={handleEditSave}
                          className="flex-1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <input
                            type="text"
                            value={editTitle}
                            onChange={(e) => setEditTitle(e.target.value)}
                            className="w-full text-[11px] px-1 border border-gray-80 rounded h-5"
                            autoFocus
                            onKeyDown={handleKeyDown}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </form>
                      ) : (
                        <div className="flex items-center gap-2 flex-1 min-w-0">
                          {currentConversationId === conversation.id && (
                            <div className="w-1 h-1 rounded-full bg-violet-40 flex-shrink-0" />
                          )}
                          <span
                            className={`text-[11px] font-medium truncate flex-1 min-w-0 ${
                              currentConversationId === conversation.id
                                ? 'text-violet-20'
                                : 'text-gray-900'
                            }`}
                          >
                            {conversation.title}
                          </span>
                        </div>
                      )}
                      <div className="flex items-center gap-1 flex-shrink-0 ml-2 h-6">
                        <span className="text-[10px] text-gray-60 group-hover:hidden whitespace-nowrap">
                          {isToday(new Date(conversation.created_at))
                            ? format(new Date(conversation.created_at), 'h:mm a')
                            : format(new Date(conversation.created_at), 'MMM d, yyyy')}
                        </span>
                        <div
                          className="hidden group-hover:flex items-center h-6"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {editingId === conversation.id ? (
                            <>
                              <ButtonV2
                                size="xs"
                                variant="ghost"
                                iconOnly
                                prefixIcon="check"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditSave(e);
                                }}
                                className="!p-1"
                              />
                              <ButtonV2
                                size="xs"
                                variant="ghost"
                                iconOnly
                                prefixIcon="cross"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditCancel();
                                }}
                                className="!p-1"
                              />
                            </>
                          ) : (
                            <>
                              <ButtonV2
                                size="xs"
                                variant="ghost"
                                iconOnly
                                prefixIcon="edit"
                                onClick={(e) => handleEditStart(conversation, e)}
                                className="!p-1"
                              />
                              <ButtonV2
                                size="xs"
                                variant="ghost"
                                iconOnly
                                prefixIcon="delete"
                                onClick={(e) => handleDelete(conversation.id, e)}
                                className="!p-1"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {conversation.last_message && (
                      <span className="text-[10px] text-gray-20 truncate mt-1">
                        {conversation.last_message}
                      </span>
                    )}
                  </div>
                </button>
              ))
            )}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
}
