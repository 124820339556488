import { Icon } from '@/components/icon/Icon';
import { ButtonV2 } from '../button/ButtonV2';

interface Props {
  tag?: React.ReactNode;
  title: React.ReactNode;
  description?: string;
  onClick: () => void;
  actions?: React.ReactNode;
}

interface DrawerProps extends Props {
  variant?: 'primary' | 'secondary';
}

export function DrawerHeaderPrimary({ tag, title, onClick, description, actions }: Props) {
  return (
    <div className="pt-6 pb-4 px-4 @md:px-8 @md:pt-7 @md:pb-6 border-b border-b-gray-90">
      <div className="flex items-center justify-between gap-2">
        <div className="space-y-1">
          {tag ? <div className="-ml-1">{tag}</div> : null}
          <p className="text-gray-05 text-xl font-poppins font-semibold leading-7 tracking-normal">
            {title}
          </p>
        </div>
        <div className="flex items-center gap-2">
          {actions}
          <ButtonV2
            autoFocus
            className="text-gray-06"
            variant="ghost"
            prefixIcon="cross"
            size="xs"
            onClick={onClick}
          />
        </div>
      </div>

      <p className="text-gray-10 text-sm font-normal leading-5 tracking-normal">{description}</p>
    </div>
  );
}

export function DrawerHeaderSecondary({ title, onClick, description }: Props) {
  return (
    <div className="py-5 px-4 @md:py-6 @md:px-8 space-y-1 flex items-center justify-between border-b border-b-gray-90 space-x-[30px]">
      <button onClick={onClick} type="button">
        <div className="flex space-x-1 text-gray-05">
          <Icon icon="arrow-left" className="w-4 h-4" />
          <p className="text-sm font-medium leading-4">Back</p>
        </div>
      </button>
      <div className="text-center">
        <p className="text-gray-05 text-base font-poppins font-semibold leading-7 tracking-normal">
          {title}
        </p>
        <p className="text-gray-10 text-xs font-normal leading-4 tracking-normal">{description}</p>
      </div>
      <div className="w-8 invisible"></div>
    </div>
  );
}

export default function DrawerHeader({
  tag,
  title,
  onClick,
  description,
  variant = 'primary',
  actions
}: DrawerProps) {
  if (variant === 'primary') {
    return (
      <DrawerHeaderPrimary
        title={title}
        onClick={onClick}
        description={description}
        tag={tag}
        actions={actions}
      />
    );
  }
  return <DrawerHeaderSecondary title={title} onClick={onClick} description={description} />;
}
