import { InertiaLinkProps, Link } from '@inertiajs/react';
import clsx from 'clsx';
import React from 'react';
import { Icon, IconName } from '@/components/icon/Icon';

interface ComponentProps {
  ref?: React.Ref<React.ReactNode>;
  className?: string;
  iconClassName?: string;
  as?: React.ElementType;
  align?: string;
  size?: ButtonSize;
  onDark?: boolean;
  loading?: boolean;
  disabled?: boolean;
  isActive?: boolean;
  iconOnly?: boolean;
  prefixIcon?: IconName;
  suffixIcon?: IconName;
  variant?: ButtonVariant;
}

interface ButtonElementProps
  extends ComponentProps,
    Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'as' | 'label'> {
  type?: 'button' | 'submit' | 'reset';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  href?: never;
  external?: never;
  ref?: any;
}

interface LinkElementProps
  extends ComponentProps,
    Omit<React.HTMLProps<HTMLAnchorElement>, 'size' | 'as'> {
  href: string;
  external: true;
  type?: never;
  ref?: any;
}

interface InertiaLinkElementProps extends ComponentProps, Omit<InertiaLinkProps, 'size' | 'as'> {
  href: string;
  external?: false;
  type?: never;
}

export type ButtonProps = ButtonElementProps | InertiaLinkElementProps | LinkElementProps;
export type Props = ButtonProps & {
  color?: Color;
  ref?: HTMLButtonElement;
};
export type ComposedButtonProps = ButtonProps & {
  label?: React.ReactNode;
};

export const buttonSizes = ['xs', 'sm', 'md'] as const;
export type ButtonSize = (typeof buttonSizes)[number];

export const buttonColors = ['grey', 'red'] as const;
export type Color = (typeof buttonColors)[number];

export const buttonVariants = ['filled', 'lighter', 'outlined', 'ghost'] as const;
export type ButtonVariant = (typeof buttonVariants)[number];

const ButtonComponent = (
  {
    children,
    className,
    iconClassName,
    as = 'button',
    align = 'center',
    color = 'grey',
    variant = 'outlined',
    size = 'md',
    external = false,
    loading = false,
    disabled = false,
    iconOnly = false,
    prefixIcon,
    suffixIcon,
    ...props
  }: Props,
  ref: any
) => {
  const Component: any = getComponent(as, external, props.href);

  prefixIcon = loading ? 'buffer' : prefixIcon;

  const typeProp = as === 'button' ? { type: 'button' } : null;

  return (
    <Component
      ref={ref}
      disabled={disabled}
      {...typeProp}
      className={clsx(
        className,
        getButtonAlignment(align),
        getButtonSize(size),
        getTextColor(color, variant, disabled),
        getButtonStyles(color, variant, disabled),
        'rounded-lg',
        size === 'md' ? 'gap-1' : 'gap-0.5',
        'group inline-flex items-center border',
        'focus:outline-none',
        'hover:cursor-pointer',
        'disabled:cursor-not-allowed',
        loading && 'opacity-75 min-w-0'
      )}
      {...props}
    >
      {prefixIcon ? (
        <StyledIcon
          className={iconClassName}
          size={size}
          variant={variant}
          disabled={disabled}
          icon={prefixIcon}
          prefix={true}
          color={color}
          loading={loading}
        />
      ) : null}

      {children && !iconOnly && (
        <p
          className={clsx(
            size === 'md' ? 'text-sm' : 'text-xs',
            'max-w-full px-1',
            '[text-decoration:inherit]',
            loading && 'truncate overflow-hidden'
          )}
        >
          {children}
        </p>
      )}
      {iconOnly && children}
      {suffixIcon ? (
        <StyledIcon
          size={size}
          icon={suffixIcon}
          prefix={false}
          color={color}
          variant={variant}
          disabled={disabled}
          loading={loading}
        />
      ) : null}
    </Component>
  );
};

const StyledIcon = ({
  icon,
  prefix,
  color,
  loading,
  size,
  variant,
  disabled,
  className
}: {
  icon: IconName;
  prefix: boolean;
  color: Color;
  loading: boolean;
  size: ButtonSize;
  variant: ButtonVariant;
  disabled: boolean;
  className?: string;
}) => {
  return (
    <span className={clsx('inline-flex flex-none items-center justify-center')}>
      <Icon
        icon={icon}
        className={clsx(
          className,
          size === 'md' ? 'h-[17.5px] w-[17.5px]' : 'h-[14px] w-[14px]',
          prefix && loading && 'animate-spin',
          getTextColor(color, variant, disabled)
        )}
      />
    </span>
  );
};

function getButtonStyles(color: Color, variant: ButtonVariant, disabled: boolean) {
  if (disabled) {
    return 'bg-gray-90 border-gray-90';
  }

  const styles = {
    grey: {
      filled: {
        base: 'bg-gray-05 border-gray-05',
        hover: hover('bg-gray-10 border-gray-100'),
        focus: focus('bg-gray-05 border-gray-05 shadow-focus-gray-90'),
        active: active('bg-gray-05 border-gray-05 shadow-none')
      },
      outlined: {
        base: 'bg-white border-gray-80',
        hover: hover('bg-gray-100 border-gray-80'),
        focus: focus('bg-white shadow-focus-gray-90 border-gray-10'),
        active: active('bg-white border-gray-10 shadow-none')
      },
      lighter: {
        base: 'bg-gray-90 border-gray-90',
        hover: hover('bg-white border-gray-80'),
        focus: focus('bg-white border-gray-10 shadow-focus-gray-90'),
        active: active('bg-white border-gray-10 shadow-none')
      },
      ghost: {
        base: 'bg-white border-white',
        hover: hover('bg-gray-100 border-gray-80'),
        focus: focus('bg-gray-100 border-gray-10 shadow-focus-gray-90'),
        active: active('bg-gray-100 border-gray-10 shadow-none')
      }
    },
    red: {
      filled: {
        base: 'bg-red-30 border-red-30',
        hover: hover('bg-red-20 border-grey-100'),
        focus: focus('bg-red-30 border-red-30 shadow-focus-red-90'),
        active: active('bg-red-30 border-red-30 shadow-none')
      },
      outlined: {
        base: 'bg-white border-red-20',
        hover: hover('bg-red-100 border-red-100'),
        focus: focus('bg-white border-red-20 shadow-focus-red-90'),
        active: active('bg-white border-red-20 shadow-none')
      },
      lighter: {
        base: 'bg-red-90 border-red-90',
        hover: hover('bg-white border-red-20'),
        focus: focus('bg-white border-red-20 shadow-focus-red-90'),
        active: active('bg-white border-red-20 shadow-none')
      },
      ghost: {
        base: 'bg-white border-white',
        hover: hover('bg-red-100 border-red-20'),
        focus: focus('bg-white border-red-20 shadow-focus-red-90'),
        active: active('bg-red-100 border-red-20 shadow-none')
      }
    }
  };

  const variantStyles = styles[color]?.[variant];

  if (!variantStyles) return [];

  return [variantStyles.base, variantStyles.hover, variantStyles.focus, variantStyles.active];
}

function getTextColor(color: Color, variant: ButtonVariant, disabled: boolean) {
  if (disabled) {
    return 'text-gray-40';
  }

  const colors = {
    brand: 'text-turquoise-10',
    grey: variant === 'filled' ? 'text-white' : 'text-gray-10',
    red: variant === 'filled' ? 'text-white' : 'text-red-20'
  };
  return colors[color];
}

function getButtonAlignment(align: string) {
  switch (align) {
    case 'left':
      return 'justify-start';
    case 'right':
      return 'justify-end';
    default:
      return 'justify-center';
  }
}

function getButtonSize(size: ButtonSize) {
  switch (size) {
    case 'xs':
      return 'py-1 px-2';
    case 'sm':
      return 'p-1.5';
    default:
      return 'p-2';
  }
}

function getComponent(as: React.ElementType, external: boolean, href?: string): any {
  if (href) {
    return external ? 'a' : Link;
  }
  return as;
}

export const ButtonV2 = React.forwardRef(ButtonComponent);
