export function isValidUrl(url: string) {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].\S*$/i;
  return url ? urlPattern.test(url) : false;
}

/**
 * Determine if a given URL is an internal route.
 */
export function isExternal(url = ''): boolean {
  return !url.startsWith(Ziggy.url);
}

export function isInternal(url = ''): boolean {
  return !isExternal(url);
}
