import { Drawer } from '@/@types/Drawer';
import { uuid } from '@/utils/UuidUtil';
import { create } from 'zustand';

export interface DrawersState {
  drawers: Drawer[];
  openDrawer: <T extends object>(drawer: Omit<Drawer<T>, 'id' | 'show'>) => string;
  closeDrawer: (id: string) => void;
  removeDrawer: (id: string) => void;
  closeDrawers: () => void;
  isDrawerOpen: (id: string) => boolean;
  setDrawers: (drawers?: Drawer[]) => void;
}

export default create<DrawersState>((set, get) => ({
  drawers: [],
  openDrawer: <T extends object>(drawer: Omit<Drawer<T>, 'id' | 'show'>): string => {
    const id = uuid();
    set((state) => ({
      drawers: [...state.drawers, { ...drawer, id, show: true }] as DrawersState['drawers']
    }));

    return id;
  },
  closeDrawer: (id: string) => {
    set((state) => {
      const index = state.drawers.findIndex((drawer) => drawer.id === id);
      const drawers = [...state.drawers];
      drawers[index].show = false;

      return {
        drawers
      };
    });
  },
  closeDrawers: () => {
    set((state) => ({
      drawers: state.drawers.map((drawer) => {
        drawer.show = false;
        return drawer;
      })
    }));
  },
  isDrawerOpen: (id: string): boolean => {
    const drawer = get().drawers.find((drawer) => drawer.id === id);
    return drawer ? drawer.show : false;
  },
  removeDrawer: (id: string) =>
    set((state) => ({
      drawers: state.drawers.filter((drawer) => drawer.id !== id)
    })),
  setDrawers: (drawers: Drawer[] = []) =>
    set(() => ({
      drawers
    }))
}));
