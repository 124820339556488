import React from 'react';
import { useKeyboardShortcut } from '@/hooks/useKeyboardShortcut';
import { useAIAssistantDrawer } from '@/drawers/AIAssistant';
import useDrawers from '@/hooks/useDrawers';
import { useAIAssistantStore } from '@/stores/aiAssistantStore';

export function AIAssistantProvider({ children }: { children: React.ReactNode }) {
  const { open } = useAIAssistantDrawer();
  const { closeDrawers } = useDrawers();
  const { isOpen, setIsOpen } = useAIAssistantStore();

  useKeyboardShortcut(
    {
      metaKey: true,
      key: 'k'
    },
    () => {
      if (isOpen) {
        closeDrawers();
        setIsOpen(false);
      } else {
        setIsOpen(true);
        open();
      }
    }
  );

  return <>{children}</>;
}
