import React from 'react';
import clsx, { ClassValue } from 'clsx';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'sm' | 'lg';
  className?: ClassValue;
  childrenClassName?: ClassValue;
  dropdownRef?: React.Ref<any>;
}

export function Dropdown({
  header,
  children,
  footer,
  size = 'lg',
  className,
  childrenClassName,
  dropdownRef
}: Props) {
  const isSmall = size === 'sm';

  return (
    <div
      className={clsx(
        className,
        'border border-gray-50 border-opacity-20 bg-gradient-to-b from-white/80 shadow-bottom-40',
        'divide-y divide-gray-90 overflow-hidden bg-white bg-opacity-100',
        isSmall ? 'rounded' : 'rounded-lg'
      )}
    >
      <div className="dropdown-ref">
        {header ? <div className={clsx(isSmall ? 'p-1' : 'p-2')}>{header}</div> : null}
        {children ? (
          <ul className={clsx(childrenClassName, 'space-y-1.5 px-1.5')} ref={dropdownRef}>
            {children}
          </ul>
        ) : null}
        {footer ? <div className="border-t border-gray-90 px-3 py-2">{footer}</div> : null}
      </div>
    </div>
  );
}
