import clsx from 'clsx';
import { BodyTextSm } from '@/components/typography/body/BodyText';
import React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  onDark?: boolean;
}

export function HelpText({ children, className, onDark = false }: Props) {
  return (
    <BodyTextSm as="p" className={clsx(className, onDark ? 'text-gray-80' : 'text-gray-30')}>
      {children}
    </BodyTextSm>
  );
}
