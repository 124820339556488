import clsx from 'clsx';
import { InertiaLinkProps, Link as InertiaLink } from '@inertiajs/react';
import { isExternal as isExternalUtil } from '@/utils/UrlUtil';
import React from 'react';

export const linkVariants = ['default', 'underlined', 'plain-text'] as const;
export type LinkVariant = (typeof linkVariants)[number];

interface Props extends InertiaLinkProps {
  children?: React.ReactNode;
  variant?: LinkVariant;
  isExternal?: boolean;
}

export const DefaultLink = (props: Props) => (
  <Link {...props} variant="default">
    {props.children}
  </Link>
);

export const UnderlinedLink = (props: Props) => (
  <Link {...props} variant="underlined">
    {props.children}
  </Link>
);

export const PlainTextLink = (props: Props) => (
  <Link {...props} variant="plain-text">
    {props.children}
  </Link>
);

export function Link({ children, className, variant = 'default', isExternal, ...props }: Props) {
  if (isExternal === undefined) {
    isExternal = isExternalUtil(props.href);
  }
  const Component: any = isExternal ? 'a' : InertiaLink;

  return (
    <Component
      className={clsx(
        className,
        ['default', 'underlined'].includes(variant) &&
          'rounded font-medium outline-2 outline-offset-2 hover:text-blue-20 hover:underline focus:underline focus:outline active:text-violet-10 active:text-shadow-glitch',
        variant === 'underlined' && 'underline active:no-underline'
      )}
      {...props}
    >
      {children}
    </Component>
  );
}
