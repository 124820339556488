import React from 'react';
import {
  autoUpdate,
  flip,
  type FloatingContext,
  size,
  useFloating,
  UseFloatingOptions,
  useInteractions
} from '@floating-ui/react';
import { offset } from '@floating-ui/dom';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { UseInteractionsTuple } from '@/@types/UseInteractionsTuple';

export const matchWidth = () =>
  size({
    apply({ elements }) {
      Object.assign(elements.floating.style, {
        width: `${elements.reference.getBoundingClientRect().width}px`
      });
    }
  });

export const dropdownOffset = () => offset(8);

interface PopperComponentProps {
  reference: React.ReactNode;
  children: React.ReactNode | ((context: FloatingContext) => React.ReactNode);
  placement?: UseFloatingOptions['placement'];
  strategy?: UseFloatingOptions['strategy'];
  middleware?: UseFloatingOptions['middleware'];
  interactions?: UseInteractionsTuple[];
  floatingOptions?: UseFloatingOptions;
  className?: any;
  wrapperClassName?: any;
  width?: 'fixed-sm' | 'fixed-md' | 'fixed-lg' | 'matchContent' | 'matchInput';
  customWidthClass?: string;
}

export type SelectBoxPopperComponentProps = Omit<PopperComponentProps, 'children' | 'reference'>;

export const Popper = ({
  reference,
  children,
  placement = 'bottom-end',
  middleware = [],
  strategy = 'fixed',
  className,
  wrapperClassName,
  width = 'matchContent',
  customWidthClass,
  interactions = [],
  floatingOptions = {},
  ...rest
}: PopperComponentProps) => {
  const { refs, context, floatingStyles, middlewareData } = useFloating({
    whileElementsMounted: autoUpdate,
    strategy,
    placement,
    middleware: [flip(), width === 'matchInput' ? matchWidth() : undefined, ...middleware],
    ...floatingOptions
  });

  const fixedWidth = {
    'fixed-sm': 'w-56',
    'fixed-md': 'w-72',
    'fixed-lg': 'w-96',
    matchContent: undefined,
    matchInput: undefined
  }[width];

  const { getReferenceProps, getFloatingProps } = useInteractions(
    interactions?.map((tuple) => tuple[0](context, tuple[1])) ?? []
  );

  return (
    <div
      ref={refs.setReference}
      {...rest}
      {...getReferenceProps()}
      className={clsx(wrapperClassName)}
    >
      {reference}
      {createPortal(
        <div
          ref={refs.setFloating}
          style={{
            ...floatingStyles,
            visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible'
          }}
          {...getFloatingProps()}
          className={clsx('z-50', fixedWidth, customWidthClass, className)}
        >
          {typeof children === 'function' ? children(context) : children}
        </div>,
        document.getElementById('popovers') || document.body
      )}
    </div>
  );
};
