import { useEffect } from 'react';

interface KeyboardShortcutOptions {
  metaKey?: boolean;
  ctrlKey?: boolean;
  altKey?: boolean;
  shiftKey?: boolean;
  key: string;
}

export function useKeyboardShortcut(options: KeyboardShortcutOptions, callback: () => void) {
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      const matchesMetaKey = options.metaKey === undefined || event.metaKey === options.metaKey;
      const matchesCtrlKey = options.ctrlKey === undefined || event.ctrlKey === options.ctrlKey;
      const matchesAltKey = options.altKey === undefined || event.altKey === options.altKey;
      const matchesShiftKey = options.shiftKey === undefined || event.shiftKey === options.shiftKey;
      const matchesKey = event.key.toLowerCase() === options.key.toLowerCase();

      if (matchesMetaKey && matchesCtrlKey && matchesAltKey && matchesShiftKey && matchesKey) {
        event.preventDefault();
        callback();
      }
    }

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [callback, options]);
}
