import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
  className?: string;
  spacingClassName?: string | null;
};

export default function DrawerBody({
  children,
  className,
  spacingClassName = 'space-y-14'
}: Props) {
  return (
    <div
      className={clsx(
        className,
        'flex flex-col flex-1 overflow-y-auto px-4 pt-4 pb-8 @md:px-8 @md:pt-8 @md:pb-16',
        spacingClassName
      )}
    >
      {children}
    </div>
  );
}
