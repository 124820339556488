import React, { useRef } from 'react';
import { withTheme } from '../theme/withTheme';
import { BodyTextSm } from '../typography/body/BodyText';
import { Label } from './Label';
import { uuid } from '@/utils/UuidUtil';
import clsx from 'clsx';
import { HelpText } from '@/components/form/HelpText';

interface Props {
  isFocused?: boolean;
  label: React.ReactNode;
  isDisabled?: boolean;
  error?: string | null;
  success?: string | null;
  helpText?: React.ReactNode | null;
  className?: string;
  children: React.ReactNode;
  size?: 'sm' | 'md' | '2xl';
  inline?: boolean;
  onDark?: boolean;
}

export const erroredFormFieldDataAttribute = 'data-errored-form-field';

export const FormField = withTheme<Props>(
  ({
    label,
    error,
    helpText,
    className,
    children,
    isDisabled,
    isFocused,
    success,
    size,
    inline,
    onDark
  }: Props) => {
    const localId = useRef(uuid());

    return (
      <div
        {...(error ? { [erroredFormFieldDataAttribute]: true } : {})}
        className={clsx([
          'flex scroll-m-2',
          inline ? 'w-full items-center gap-5' : 'flex-col',
          !inline && (size === 'sm' ? 'gap-1' : 'gap-2'),
          className
        ])}
      >
        {label ? (
          <Label
            id={localId.current}
            hasError={!!error}
            hasSuccess={!!success}
            isDisabled={isDisabled}
            size={size}
            inline={inline}
          >
            {label}
          </Label>
        ) : null}

        <>
          {React.Children.map(children, (child: any) =>
            React.cloneElement(child, {
              id: localId.current,
              autoFocus: isFocused
            })
          )}
        </>

        {helpText ? (
          <HelpText className="mt-1" onDark={onDark}>
            {helpText}
          </HelpText>
        ) : null}

        {error ? (
          <BodyTextSm as="p" className="text-functional-on-light-error">
            {error}
          </BodyTextSm>
        ) : null}
      </div>
    );
  }
);
